import { AxiosRequestConfig } from 'axios';

class ApiConfigs {
    generatePrintForm: (formId: string, templateId: string) => AxiosRequestConfig = (formId, templateId) => {
        return {
            method: 'POST',
            url: '/pf',
            params: {
                template: templateId,
                customForm: formId,
            },
        };
    };
}

export default new ApiConfigs();
