import React from 'react';
import { Root as RootICS, RootStoreProps } from '@platform/ics-front-core';
import { HeaderLinksStoreDI } from './di/store/HeaderLinksStore';
import { RequestStoreDI } from './di/store/RequestStore';
import { SelectRegFormDI } from './di/pages/campaign-request/campaign-request-list-page/campaign-requests-list-filters/CampaignRequestListFilterPanel';
import { Router } from 'react-router-dom';
import { PersonStoreDI } from './di/store/PersonStore';
import { CampaignRequestPrintFormsTableDi } from './di/pages/campaign-request/campaign-request-page/campaign-request-page-print-forms/campaign-request-page-print-form-table/CampaignRequestPrintFormsTable';

export const Root = (props: RootStoreProps): JSX.Element => {
    return (
        <Router history={props.history}>
            <RootICS
                {...props}
                use={[
                    PersonStoreDI,
                    RequestStoreDI,
                    CampaignRequestPrintFormsTableDi,
                    SelectRegFormDI,
                    HeaderLinksStoreDI,
                ]}
            />
        </Router>
    );
};
