import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import { IObservableArray } from 'mobx';
import { CampaignRequestPfModel } from '../../../../../models/campaign-request-pf/CampaignRequestPfModel';
import {
    CampaignRequestPrintFormsTableProps as CampaignRequestPrintFormsTablePropsICS,
    CampaignRequestPrintFormsTable as CampaignRequestPrintFormsTableInj,
} from '@platform/ics-front-core';
import { useLocation } from 'react-router-dom';
import { CampaignRequestPrintFormsRow } from './CampaignRequestPrintFormsRow';
import { injectable } from 'react-magnetic-di';

type CampaignRequestPrintFormsTableProps = CampaignRequestPrintFormsTablePropsICS & {
    printForms: IObservableArray<CampaignRequestPfModel>;
};

type RouteParams = {
    id: string;
};

export const CampaignRequestPrintFormsTable = observer(
    (props: CampaignRequestPrintFormsTableProps): JSX.Element => {
        const { validateEditPage, printForms, validateReadonlyPage } = props;

        const location = useLocation();
        const isEditPage: boolean = location.pathname.includes('edit');

        const handleGenerateAction = (printForm: CampaignRequestPfModel) => (): void => {
            const validateFunction = isEditPage ? validateEditPage : validateReadonlyPage;
            printForm.generatePrintForm(validateFunction, isEditPage);
        };

        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '30%' }}>
                                <Typography>
                                    <FormattedMessage id="campaignRequest.printFormsTable.fileColumnName" />
                                </Typography>
                            </TableCell>
                            <TableCell style={{ width: '30%' }}>
                                <Typography>
                                    <FormattedMessage id="campaign.documentCategory" />
                                </Typography>
                            </TableCell>
                            <TableCell align="center" style={{ width: '30%' }}>
                                <Typography>
                                    <FormattedMessage id="campaign.delayedTask" />
                                </Typography>
                            </TableCell>
                            <TableCell style={{ width: '30%' }}>
                                <Typography>
                                    <FormattedMessage id="campaignRequest.printFormsTable.crcCode" />
                                </Typography>
                            </TableCell>
                            <TableCell style={{ width: '20%' }}>
                                <Typography>
                                    <FormattedMessage id="campaignRequest.printFormsTable.dateColumnName" />
                                </Typography>
                            </TableCell>
                            <TableCell style={{ width: '20%' }}>
                                <Typography>
                                    <FormattedMessage id="common.actions" />
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {printForms.map((printForm) => {
                            return (
                                <CampaignRequestPrintFormsRow
                                    printForm={printForm}
                                    handleGenerateAction={handleGenerateAction}
                                />
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    },
);

export const CampaignRequestPrintFormsTableDi = injectable(
    CampaignRequestPrintFormsTableInj,
    (props: CampaignRequestPrintFormsTableProps) => {
        return <CampaignRequestPrintFormsTable {...props} />;
    },
);
