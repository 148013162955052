import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    AuthorizationCheck,
    AutocompleteProps,
    Select,
    SelectRegFormInj,
    SelectRegFormProps,
    textFieldProps,
    useStore,
} from '@platform/ics-front-core';
import { entities, permissions } from '../../../../../authSchemeConfig';
import { injectable } from 'react-magnetic-di';
import { observer } from 'mobx-react';

export const SelectRegForm = observer(
    (props: SelectRegFormProps): JSX.Element => {
        const { model, intl } = props;
        const { filtering } = model;
        return (
            <AuthorizationCheck entityCode={entities.System} permCode={permissions.System.ViewReqFormList}>
                {(allowed: boolean): JSX.Element => (
                    <Select
                        multiple={true}
                        textFieldProps={{ ...textFieldProps }}
                        selectData={model.regFormFilterData}
                        onChange={filtering.regForm.onChange}
                        values={filtering.regForm.values}
                        label={<FormattedMessage id="campaignRequest.registryColumns.regForm" />}
                        placeholder={intl.formatMessage('common.all')}
                        autoCompleteProps={{
                            ...AutocompleteProps,
                            disabled: !allowed,
                        }}
                    />
                )}
            </AuthorizationCheck>
        );
    },
);

export const SelectRegFormDI = injectable(SelectRegFormInj, (props) => <SelectRegForm {...props} />);
