import { clientRoute, PersonStore as PersonStoreICS, RootStore } from '@platform/ics-front-core';
import { computed } from 'mobx';
import { injectable } from 'react-magnetic-di';

export class PersonStore extends PersonStoreICS {
    @computed
    get redirectPath(): string {
        return clientRoute.campaigns;
    }
}

export const PersonStoreDI = injectable(PersonStoreICS, PersonStore);
